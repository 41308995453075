import React, { Component } from 'react';
import './App.css';
import Header from './components/Header/Header';
import ImageContainer from './components/ImageContainer/ImageContainer';
import ScoreboardContainer from './components/ScoreboardContainer/ScoreboardContainer'

class App extends Component {

  handleClick = () => {
    window.location.href = "https://www.oac.org/landing/headlines-featured";
  }

  render() {
    return (
      <div>
        <div className="title-wrapper">
          <h1 className='title'>OHIO ATHLETIC CONFERENCE</h1>
        </div>
        <Header />
        <ImageContainer />
        <ScoreboardContainer />
        <button onClick={this.handleClick}>View OAC News</button>
      </div>
    );
  }

}

export default App;