import React from 'react';

function Header() {
  return (
    <header>
      <div id = "oac_img">
        <img id = "oac_logo" src="2020_oac.png" alt="OAC Sports"></img>
      </div>
    </header>
  );
}

export default Header;