import React, { Component } from 'react';
import { CookiesProvider } from "react-cookie";

import Selector from './Selector/Selector';
import './SelectorContainer.css';

class SelectorContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            genderOptions: ["Men's Sports", "Women's Sports"],
            sportOptions: ["Basketball", "Baseball", "Cross Country", "Football", "Golf", "Lacrosse", "Soccer", "Swimming & Diving", "Tennis", "Track & Field", "Wrestling"],
            gender: "Men's Sports",
            sport: "Basketball"
          };
        this.handleGenderChange = this.handleGenderChange.bind(this);
        this.handleSportChange = this.handleSportChange.bind(this);
    }


    handleGenderChange(option) {
      let sportOptions;
  
      if (option === "Men's Sports") {
        sportOptions = ["Basketball", "Baseball", "Cross Country", "Football", "Golf", "Lacrosse", "Soccer", "Swimming & Diving", "Tennis", "Track & Field", "Wrestling"];
      } else {
        sportOptions = ["Basketball", "Cross Country", "Golf", "Lacrosse", "Soccer", "Softball", "Swimming & Diving", "Tennis", "Track & Field", "Volleyball"];
      }
  
      document.cookie = "gender=" + option;
      this.setState({sportOptions: sportOptions, gender: option, sport: sportOptions[0]});
      this.props.onChange(option, sportOptions[0]);
    }
    
    handleSportChange(option) {
      this.setState({sport: option});
      this.props.onChange(this.state.gender, option);
    }

    render() {
      return (
          <div className='SelectorWrapper'>
            <div className='GenderSelector'>
              <Selector option={this.state.gender} options={this.state.genderOptions} onChange={this.handleGenderChange}/>
            </div>

            <div className='SportSelector'>
              <Selector option={this.state.sport} options={this.state.sportOptions} onChange={this.handleSportChange}/>
            </div>
          </div>
      );
    }
}

export default SelectorContainer;