import React, { Component } from 'react';

import SelectorContainer from './SelectorContainer/SelectorContainer'
import Scoreboard from './Scoreboard/Scoreboard'
import Schedule from './Schedule/Schedule'
import Results from './Results/Results';

import SwipeableViews from 'react-swipeable-views';

class ScoreboardContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeScoreboardIndex: 0,
            sportID: 'mbkb',
            isAutoSwiping: true
          };
          this.handleSelectorChange = this.handleSelectorChange.bind(this);
    }
    
    componentDidMount() {
      this.autoSwipeTimer = setInterval(this.autoSwipe, 8000); // 8 second cooldown on auto swiping
    }
    
    componentWillUnmount() {
      clearInterval(this.autoSwipeTimer);
    }
  
    autoSwipe = () => {
      const { activeScoreboardIndex, isAutoSwiping } = this.state;
      if (isAutoSwiping === true) {
        const nextIndex = (activeScoreboardIndex + 1) % 3;
        this.setState({ activeScoreboardIndex: nextIndex });
      }
    };
  
    handleSwipe = (index) => {
      this.setState({ activeScoreboardIndex: index, isAutoSwiping: false });
    };

    handleSelectorChange(gender, sport) {
      const menDict = {
        "Basketball" : "mbkb", 
        "Baseball": "bsb", 
        "Cross Country": "mxc", 
        "Football": "fball", 
        "Golf": "mgolf", 
        "Lacrosse": "mlax", 
        "Soccer": "msoc", 
        "Swimming & Diving": "mswimdive", 
        "Tennis": "mten", 
        "Track & Field": "mtrack", 
        "Wrestling": "wrest",
      };

      const womenDict = {
        "Basketball" : "wbkb", 
        "Softball": "sball", 
        "Cross Country": "wxc", 
        "Golf": "wgolf", 
        "Lacrosse": "wlax", 
        "Soccer": "wsoc", 
        "Swimming & Diving": "wswimdive", 
        "Tennis": "wten", 
        "Track & Field": "wtrack", 
        "Volleyball": "wvball"
      };

      if (gender === "Men's Sports") {
        this.setState({ sportID: menDict[sport] });
      } else {
        this.setState({ sportID: womenDict[sport] });
      }
    }

    render() {
      return (
          <div>
            <SelectorContainer onChange={this.handleSelectorChange}/>
            <SwipeableViews
              index={this.state.activeScoreboardIndex}
              onChangeIndex={this.handleSwipe}
              enableMouseEvents
            >
            
            <div>
              <h1 className ="scoreboard-title"> Standings <span class="rightArrow"></span></h1>
              
              <Scoreboard id={this.state.sportID}/>
            </div>

            <div>
              <h1 className ="scoreboard-title"><span class="leftArrow"></span> Schedule <span class="rightArrow"></span></h1>
              <Schedule id={this.state.sportID}/>
            </div>

            <div>
              <h1 className ="scoreboard-title"><span class="leftArrow"></span> Results </h1>
              <Results id = {this.state.sportID}/>
            </div>

            </SwipeableViews>
          </div>
      );
    }
}

export default ScoreboardContainer;