import React, { Component } from 'react';
import './Results.css';

class Results extends Component {
    constructor(props) {
        super(props);
        this.state = {
            results: []
        };
    }

    componentDidMount() {
        fetch('api/results')
            .then(response => response.json())
            .then(data => this.setState({ results: data }))
            .catch(error => console.log(error));
    }
    
    render() {
        const sportID = this.props.id;

        /* Checks to see if an actual list of JSON objects was sent. This is helpful in the
            rare case that someone loads into the website the milisecond that new data is being
            scraped. The html below is what will be displayed instead of the scoreboards.
            */
        if (this.state.results.length === 0) {
            return <div className="error">Results Scoreboard Error: Please refresh the page...</div>;
        } else if (JSON.stringify(this.state.results.find(obj => obj[sportID])[sportID]) === '{}') {
            return <div className="error">Results Scoreboard Not Available</div>;
        }

        const teams = Object.entries(this.state.results.find(obj => obj[sportID])[sportID]);

        return (
            <div className="Results">
                <table className='results-table'>
                    <tbody>
                        {teams.map(([event, data], index) => (
                            <tr key={data}>
                                <td>
                                <div class="event-box">
                                    <div class="event-info clearfix">
                                        <div class="date clearfix">{data.Date}</div>
                                        <div class="sport-status clearfix">
                                            <span class="sport">{data.Sport}</span>  <span class="status">{data.Status}</span>
                                        </div>
                                        <div class="teams clearfix">
                                            <div class="team clearfix">
                                                <div class="team-logo"><img src= {data['Logo 1']} width = "30" alt=""></img>
                                                <span class="team-name">{data['Teamname 1']}</span>
                                                <span class="result float-right">{data['Results 1']}</span>
                                                </div>    
                                            </div>
                                            <div class="team clearfix">
                                                <div class="team-logo"><img src= {data['Logo 2']} width = "30" alt=""></img>
                                                <span class="team-name">{data['Teamname 2']}</span>
                                                <span class="result float-right">{data['Results 2']}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Results;