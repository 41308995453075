import React, { Component } from 'react';

import './Scoreboard.css'

class Scoreboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            standings: []
        };
    }

    componentDidMount() {
        fetch('api/standings')
            .then(response => response.json())
            .then(data => this.setState({ standings: data }))
            .catch(error => console.log(error));
    }

    render() {
        const sportID = this.props.id;

        /* Checks to see if an actual list of JSON objects was sent. This is helpful in the
            rare case that someone loads into the website the milisecond that new data is being
            scraped. The html below is what will be displayed instead of the scoreboards.
            */
        if (this.state.standings.length === 0) {
            return <div className="error">Standings Scoreboard Error: Please refresh the page...</div>;
        } else if (JSON.stringify(this.state.standings.find(obj => obj[sportID])[sportID]) === '{}') {
            return <div className="error">Standings Scoreboard Not Available</div>;
        }

        const teams = Object.entries(this.state.standings.find(obj => obj[sportID])[sportID]);

        const imgNames = {
            "Baldwin Wallace": "bwlogo.png",
            "Capital": "caplogo.png",
            "Heidelberg": "hblogo.png",
            "John Carroll": "jclogo.png",
            "Marietta": "marlogo.png",
            "Mount Union": "mountlogo.png",
            "Muskingum": "musklogo.png",
            "Ohio Northern": "onulogo.png",
            "Otterbein": "ottlogo.png",
            "Wilmington": "wilmlogo.png"
        };
      
        return (
            <div className="scoreboard">
                <table className="table">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Schools</th>
                        <th>Conf</th>
                        <th>Overall</th>
                    </tr>
                    </thead>
                    <tbody>
                    {teams.map(([team, scores], index) => (
                        <tr key={team}>
                        <td id="image"><img  src={`${imgNames[team]}`} width="40" alt="School Logo" /></td>
                        <td>{team}</td>
                        <td>{scores.Conf}</td>
                        <td>{scores.Overall}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Scoreboard;