import React, { Component } from 'react';
import './Schedule.css';

class Schedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schedule: []
        };
    }

    componentDidMount() {
        fetch('api/schedule')
            .then(response => response.json())
            .then(data => this.setState({ schedule: data }))
            .catch(error => console.log(error));
    }
    
    render() {
        const sportID = this.props.id;

        /* Checks to see if an actual list of JSON objects was sent. This is helpful in the
            rare case that someone loads into the website the milisecond that new data is being
            scraped. The html below is what will be displayed instead of the scoreboards.
            */
        if (this.state.schedule.length === 0) {
            return <div className="error">Schedule Scoreboard Error: Please refresh the page...</div>;
        } else if (JSON.stringify(this.state.schedule.find(obj => obj[sportID])[sportID]) === '{}') {
            return <div className="error">Schedule Scoreboard Not Available</div>;
        }

        const teams = Object.entries(this.state.schedule.find(obj => obj[sportID])[sportID]);

        return (
            <div className="Schedule">
                <table className='schedule-table'>
                    <tbody>
                        {teams.map(([event, data], index) => (
                            <tr key={index}>
                                <td>
                                    <div className="date-logo">{data.Date}</div>
                                    <div className="sport-status clearfix">
                                        <span className="sport">{data.Sport}</span>  <span className="status">{data.Time}</span>
                                    </div>
                                    <div className="teams clearfix">
                                        <div className="team clearfix">
                                            <div className="team-logo"><img src = {data['Logo 1']} width="30" alt=""></img><span className="team-name">{data['Teamname 1']}</span></div>
                                            
                                        </div>
                                        <div className="team clearfix">
                                            <div className="team-logo"><img src = {data['Logo 2']} width="30" alt=""></img><span className="team-name">{data['Teamname 2']}</span></div>
                                        </div>
                                    </div>
                                    <div className="links empty">
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Schedule;