import React from 'react';

function ImageContainer() {
  return (
    <div class="center">
      <div class="logo-container">
          <a href="https://www.bwyellowjackets.com/landing/index"><img src="bwlogo.png" class="logo" alt="Baldwin Wallace"></img></a>
          <a href="https://athletics.capital.edu/"><img src="caplogo.png" class="logo" alt="Capital"></img></a>
          <a href="http://www.bergathletics.com/"><img src="hblogo.png" class="logo" alt="Heidelberg"></img></a>
          <a href="http://www.jcu.edu/athletics/"><img src="jclogo.png" class="logo" alt="John Carroll"></img></a>
          <a href="http://pioneers.marietta.edu/"><img src="marlogo.png" class="logo" alt="Marietta"></img></a>
          <a href="http://athletics.mountunion.edu/landing/index"><img class="raiders" src="mountlogo.png" alt="Mount Union"></img></a>
          <a href="http://www.fightingmuskies.com/landing/index"><img class="logo" src="musklogo.png" alt="Muskingum"></img></a>
          <a href="http://www.onu.edu/athletics"><img src="onulogo.png" class="logo" alt="Ohio Northern"></img></a>
          <a href="http://www.otterbeincardinals.com/"><img src="ottlogo.png" class="logo" alt="Otterbein"></img></a>
          <a href="http://www.wilmingtonquakers.com/"><img src="wilmlogo.png" class="quakers" alt="Wilmington"></img></a> 
      </div>
    </div>
  );
}


export default ImageContainer;
